import React, { useRef, useState } from 'react';
import styled from 'styled-components';
type Props = {
  src?: any;
};
const VideoViewer = ({ src }: Props) => {
  const video = useRef<HTMLVideoElement>(null);
  const canvas = useRef<HTMLCanvasElement>(null);
  const [thumbnail, setThumbnail] = useState('');
  if (!src) return null;
  return (
    <ViewContainer>
      <canvas ref={canvas} style={{ display: 'none' }}></canvas>
      <video ref={video} src={src} muted onLoadedData={() => {
        if (!video.current || !canvas.current) return;
        video.current.currentTime = 1;
        video.current.pause();
        const ctx = canvas.current.getContext('2d');
        if (!ctx) return;
        // Vẽ frame đầu tiên lên canvas
        canvas.current.width = video.current.videoWidth;
        canvas.current.height = video.current.videoHeight;
        ctx.drawImage(video.current, 0, 0);
        const dataURL = canvas.current.toDataURL('image/png');
        setThumbnail(dataURL); // Gán hình ảnh vào thẻ img
        canvas.current.style.display = 'none'; //
      }} />
      {thumbnail && <img src={thumbnail} alt="Video Thumbnail" />}
    </ViewContainer>
  );
};

export default VideoViewer;
const ViewContainer = styled.div`
  position: relative;
`;