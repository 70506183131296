import { Carousel } from 'antd';
import Modal from 'components/Modal';
import { concat, find, first } from 'lodash';
import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import styled from 'styled-components';
import { PlayCircleOutlined } from '@ant-design/icons';
import VideoViewer from './VideoViewer';

export type IUIMediaItem = {
  id: string;
  source: string;
  type: string;
};

type Ref = {
  open: (media: IUIMediaItem[], activeId?: string) => void;
  close: () => void;
};
export const mediaModalReviewRef = React.createRef<Ref>();
const slidesPerRow = 3;
const MediaModalReview = forwardRef<Ref>(({ }, ref) => {
  const [open, setOpen] = useState(false);
  const [activeFile, setActiveFile] = useState<IUIMediaItem | null>(null);
  const [files, setFiles] = useState<IUIMediaItem[]>([]);
  const onClose = () => setOpen(false);
  useImperativeHandle(ref, () => ({
    open: (_files, activeId) => {
      setOpen(true);
      setFiles(_files);
      setActiveFile(find(_files, o => o.id === activeId) || first(_files) || null);
    },
    close: () => {
      setOpen(false);
    }
  }));
  const medias = useMemo(() => {
    const length = files.length;
    const addOnNum = (slidesPerRow - length % slidesPerRow) !== slidesPerRow ? slidesPerRow - length % slidesPerRow : 0;
    const _medias = concat(files, Array.from(Array(addOnNum).keys()).map(() => null));
    return _medias;
  }, [files]);
  return (
    <Modal
      width='auto'
      height='95vh'
      className='modal-max-height-unset modal-overflow-unset modal-transparent'
      hiddenHeader
      styles={{ mask: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } }}
      open={open} forceRender footer={null} onClose={onClose} closeIcon={null}>
      <Container>
        <div className="main-media">
          {activeFile && (
            activeFile.type === 'video' ?
              <video src={activeFile.source} autoPlay muted controls /> :
              <img src={activeFile.source} />
          )}
        </div>
        <div className="media-list">
          <div className="scroll-div">
            <Carousel slidesPerRow={slidesPerRow}>
              {medias.map((o, i) => {
                if (!o) return <div key={i}></div>;
                return (
                  <div key={o.id} className="media-item" onClick={() => setActiveFile(o)}>
                    {o.type === 'video' ?
                      <VideoViewer src={o.source} /> :
                      <img src={o.source} alt={o.source} />}
                    {o.type === 'video' && <div className='video-overlay'><PlayCircleOutlined /></div>}
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
        <div className="close-button-container">
          <button onClick={onClose} className="btn btn-close"><span>Close</span></button>
        </div>
      </Container>
    </Modal>
  );
});
MediaModalReview.displayName = 'MediaModalReview';

export default MediaModalReview;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  .main-media {
    align-self: stretch;
    max-height: 495px;
    min-height: 50vh;
    display: flex;
    align-items: center;
    img, video {
      object-fit: cover;
      width: 100%;
      height: auto;
    }
  }
  .media-list {
    align-self: stretch;
    display: flex;
    position: relative;
    height: 112px;
    .scroll-div {
      position: absolute;
      inset: 0;
      overflow: hidden;
    }
    .media-item {
      height: 112px;
      position: relative;
      img, video {
        object-fit: cover;
        width: calc(100% - 8px);
        height: 100%;
        border-radius: 8px;
      }

      .video-overlay {
        position: absolute;
        z-index: 2;
        inset: 0;
        right: 8px;
        background: rgba(0, 0, 0, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        color: #fff;
      }

      &:last-child {
        video, img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .close-button-container {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    button.btn.btn-close {
      display: flex;
      height: 40px;
      background: #fff;
      padding: 16px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 5px;
      span {
        color: #0085E7;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
`;